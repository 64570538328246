import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-iframe-map',
  templateUrl: './iframe-map.component.html',
  styleUrls: ['./iframe-map.component.css']
})
export class IframeMapComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private dialogRef: MatDialogRef<IframeMapComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public setModalWidth() {
    const widthResult: number = document.getElementById('mapaModal').getBoundingClientRect().width - 25;
    return widthResult;
  }
}
