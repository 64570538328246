import { Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { HomeService } from './home.service';
import { HomeInformation } from 'src/app/classes/HomeInformation';

@Component({
  selector: 'app-main',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewChecked {

  public homeInformation: HomeInformation = new HomeInformation();
  constructor(private cdRef: ChangeDetectorRef, private homeService: HomeService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.homeService.getHomeInformation().subscribe((homeInformation: HomeInformation) => {
      this.homeInformation = homeInformation;
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}
