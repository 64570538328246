import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './components/shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EmpresaComponent } from './components/pages/empresa/empresa.component';
import { DashboardProductosComponent } from './components/dashboardProductos/dashboardProductos.component';
import { NgxSpinnerModule } from "ngx-spinner";

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { IframeMapComponent } from './components/shared/sucursales/iframe-map/iframe-map.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { MatAutocompleteModule, MatChipsModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    DashboardProductosComponent,
    HomeComponent,
    ContactComponent,
    EmpresaComponent,
    IframeMapComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
  ],
  exports: [],
  entryComponents: [IframeMapComponent],
  providers: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
