import { TipoProducto } from './TipoProducto';

export class ProductView {
  public Title: string;
  public SubTitle: string;
  public TipoProductos: Array<TipoProducto>;

  public constructor() {

  }
}
