import { Component, OnInit } from '@angular/core';
import { faBell, faArrowRight, faCertificate, faTimes, faPlus, faThLarge, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ListaProductosService } from './lista-productos.service';
import { ListaProductosView } from 'src/app/classes/ListaProductosView';
import { Producto } from 'src/app/classes/Producto';
import { ProductoDivision } from 'src/app/classes/ProductoDivision';
import { SubDivision } from 'src/app/classes/SubDivision';
import { ProductView } from 'src/app/classes/ProductView';
import { TipoProductosService } from '../tipo-productos/tipo-productos.service';
import { TipoProducto } from 'src/app/classes/TipoProducto';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.scss']
})
export class ListaProductosComponent implements OnInit {
  public faArrowRight: IconDefinition = faArrowRight;
  public listaProductosView: ListaProductosView = new ListaProductosView();
  public showLista: boolean = true;
  public productoDetalle: Producto = new Producto();

  public titulo: string = '';
  public subTitulo: string = '';
  public listaTipoProductos: Array<TipoProducto> = new Array<TipoProducto>();
  public tipoProductoSelected: TipoProducto = new TipoProducto();

  constructor(private router: Router, private route: ActivatedRoute, private listaProductoService: ListaProductosService, private tipoProductosService: TipoProductosService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.route.params.subscribe((params: Params) => {
      this.getListaProductos(params['id']);
      this.showLista = true;
      this.productoDetalle = new Producto();

      this.tipoProductosService.getListaTiposProductos().subscribe((productView: ProductView) => {
        this.listaTipoProductos = productView.TipoProductos.filter((tipoProducto: TipoProducto) => tipoProducto.IdProducto != params['id']);
        this.tipoProductoSelected = productView.TipoProductos.filter((tipoProducto: TipoProducto) => tipoProducto.IdProducto == params['id'])[0];
      });
    });
  }

  public goToProducto(idProducto: number): void {
    this.router.navigate(['lista-productos/', idProducto]);
  }

  public verDetalle(itemCard: Producto, titulo: string, subtitulo: string): void {
    this.productoDetalle = itemCard;
    this.titulo = (titulo ? titulo : '');
    this.subTitulo = (subtitulo ? subtitulo : '');
    this.showLista = false;
  }

  public goBack(): void {
    this.showLista = true;
    this.productoDetalle = new Producto();
  }

  private getListaProductos(idTipoProducto: number): void {
    this.listaProductoService.getListaTiposProductos().subscribe((listaProductos: Array<ListaProductosView>) => {
      this.listaProductosView = listaProductos.filter((listaProductoView: ListaProductosView) => {
        return listaProductoView.IdTipoProducto === Number(idTipoProducto);
      })[0];

      this.route.queryParams.subscribe((params: IQueryParams) => {
        if (params.title) {
          let producto: Producto = new Producto();

          this.listaProductosView.Division.forEach((division: ProductoDivision) => {
            producto = (division.Productos ? division.Productos.find((producto: Producto) => producto.Titulo === params.title) : null);

            if (!producto) {
              if (division.SubDivision) {
                division.SubDivision.forEach((subdivision: SubDivision) => {
                  const productoSubdivision: Producto = subdivision.Productos.find((producto: Producto) => producto.Titulo === params.title);


                  if (productoSubdivision) {
                    this.verDetalle(productoSubdivision, division.Titulo, subdivision.Titulo);
                  }
                });
              }
            } else {
              this.verDetalle(producto, division.Titulo, '');
            }
          });
        }
      });
    });
  }
}

interface IQueryParams {
  title: string;
}
