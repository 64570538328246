import { Component, OnInit} from '@angular/core';
import { faBell, faArrowRight, faCertificate, faTimes, faPlus, faThLarge } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-productos',
  templateUrl: './dashboardProductos.component.html',
  styleUrls: ['./dashboardProductos.component.scss']
})


export class DashboardProductosComponent implements OnInit {
  faBell = faBell;
  faArrowRight = faArrowRight;
  faCertificate = faCertificate;
  faTimes = faTimes;
  faThLarge = faThLarge;
  faPlus = faPlus;

  constructor(
  ) { }

  ngOnInit() {
  }

}
