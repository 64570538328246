import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConsultaEmail } from 'src/app/classes/ConsultaEmail';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) { }

  public getContactInformation() {
    const endpoint: string = "../../../assets/Endpoints/Response/getContactInformation.json";
    return this.http.get<any>(endpoint)
      .pipe(
        tap(() => { })
      );
  }

  public sendEmail(consultaEmail: ConsultaEmail) {
    const endpoint: string = "https://brainmedicalemailservice20211012143751.azurewebsites.net/Api/Email/SetSendEmail";
    //const endpoint: string = "https://localhost:44366/Api/Email/SetSendEmail";
    return this.http.post<any>(endpoint, consultaEmail)
      .pipe(
        tap(() => { })
      );
  }
}
