import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { DashboardProductosComponent } from './components/dashboardProductos/dashboardProductos.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ListaProductosComponent } from './components/shared/lista-productos/lista-productos.component';
import { ProductoDetalleComponent } from './components/pages/producto-detalle/producto-detalle.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'products', component: DashboardProductosComponent},
  {path: 'lista-productos/:id', component: ListaProductosComponent},
  {path: 'contact', component: ContactComponent},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
