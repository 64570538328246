import { ProductoDivision } from './ProductoDivision';

export class ListaProductosView{
    public IdTipoProducto: number;
    public Division: Array<ProductoDivision>;

    public constructor(){

    }
}
