import { Component, OnInit } from '@angular/core';
import { TipoProductosService } from './tipo-productos.service';
import { ProductView } from 'src/app/classes/ProductView';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tipo-productos',
  templateUrl: './tipo-productos.component.html',
  styleUrls: ['./tipo-productos.component.scss']
})
export class TipoProductosComponent implements OnInit {
  public productView: ProductView = new ProductView();

  constructor(private tipoProductosService: TipoProductosService, private router: Router) { }

  ngOnInit() {
    this.tipoProductosService.getListaTiposProductos().subscribe((productView: ProductView) => {
      this.productView = productView;
    });
  }

  public goToProducto(idProducto: number): void{
    this.router.navigate(['lista-productos/', idProducto]);
  }

  public isMobile(){
    return window.screen.width <= 500;
  }
}
