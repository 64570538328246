export class TipoProducto {
  public IdProducto: number;
  public Nombre: string;
  public Icon: string;
  public Posicion: string;
  public ContainerClass: string;

  public constructor(){

  }
}
