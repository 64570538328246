import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from '../../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactComponent } from './contact/contact.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { MatCardModule } from '@angular/material/card';
import { ListaProductosComponent } from './lista-productos/lista-productos.component';
import { TipoProductosComponent } from './tipo-productos/tipo-productos.component';
import {MatListModule} from '@angular/material/list';
import { ProductoDetalleComponent } from '../pages/producto-detalle/producto-detalle.component';
import {MatButtonModule} from '@angular/material/button';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PageTitleComponent } from './page-title/page-title.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { MatAutocompleteModule, MatChipsModule, MatDialogModule, MatFormFieldModule } from '@angular/material';

@NgModule({
  declarations: [
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    HeaderComponent,
    ContactComponent,
    TipoProductosComponent,
    BeneficiosComponent,
    SucursalesComponent,
    ListaProductosComponent,
    ProductoDetalleComponent,
    FilePreviewComponent,
    PageTitleComponent,
    SearchBoxComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDialogModule
  ],
  exports: [
    NavbarComponent,
    FontAwesomeModule,
    CommonModule,
    FooterComponent,
    HttpClientModule,
    HeaderComponent,
    ContactComponent,
    TipoProductosComponent,
    BeneficiosComponent,
    SucursalesComponent,
    MatCardModule,
    MatListModule,
    ProductoDetalleComponent,
    MatButtonModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDialogModule
  ],
  entryComponents: [FilePreviewComponent]
})
export class SharedModule { }

