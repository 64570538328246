import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { zip } from 'rxjs';
import { ListaProductosView } from 'src/app/classes/ListaProductosView';
import { Producto } from 'src/app/classes/Producto';
import { ProductoDivision } from 'src/app/classes/ProductoDivision';
import { ProductView } from 'src/app/classes/ProductView';
import { SubDivision } from 'src/app/classes/SubDivision';
import { TipoProducto } from 'src/app/classes/TipoProducto';
import { ListaProductosService } from '../lista-productos/lista-productos.service';
import { TipoProductosService } from '../tipo-productos/tipo-productos.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  @Input() showTipos: boolean;

  public faSearch: IconDefinition = faSearch;
  public searchBar = new FormControl();

  public listaProductos: Array<ListaProductosView>;
  public listaTipoProductos: Array<TipoProducto> = new Array<TipoProducto>();

  public listaTipoProductosSearch: Array<ISearchResult> = new Array<ISearchResult>();
  public listaCategorias: Array<ISearchResult> = new Array<ISearchResult>();
  public listaSubCategorias: Array<ISearchResult> = new Array<ISearchResult>();
  public listaProds: Array<ISearchResult> = new Array<ISearchResult>();

  constructor(private productosService: ListaProductosService, private tipoProductoService: TipoProductosService, private router: Router) { }

  ngOnInit() {
    this.inicializar();
  }

  public getSearchMatch(term: string) {
    this.listaTipoProductosSearch = new Array<ISearchResult>();
    this.listaCategorias = new Array<ISearchResult>();
    this.listaSubCategorias = new Array<ISearchResult>();
    this.listaProds = new Array<ISearchResult>();

    if (term && term.length >= 3) {
      const listaTiposProducto: Array<ISearchResult> = (this.showTipos ? this.listaTipoProductos.filter((tipoProducto: TipoProducto) => {
        return tipoProducto.Nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().match(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
      }).map((tipo: TipoProducto) => { return { 'IdTipoProducto': tipo.IdProducto, 'Titulo': tipo.Nombre } }) : [])

      this.listaTipoProductosSearch = this.listaTipoProductosSearch.concat(listaTiposProducto);

      this.listaProductos.forEach((productoView: ListaProductosView) => {
        const listaDivision: Array<ISearchResult> = (productoView.Division ? productoView.Division.filter((division: ProductoDivision) => {
          return division.Titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().match(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
        }).map((productoDivision: ProductoDivision) => { return { 'IdTipoProducto': productoView.IdTipoProducto, 'Titulo': productoDivision.Titulo + ' - ' + this.listaTipoProductos.find((tipoProducto: TipoProducto) => tipoProducto.IdProducto === productoView.IdTipoProducto).Nombre } }) : [])

        this.listaCategorias = this.listaCategorias.concat(listaDivision);

        if (productoView.Division) {
          productoView.Division.forEach((division: ProductoDivision) => {
            const listaSubDivision: Array<ISearchResult> = (division.SubDivision ? division.SubDivision.filter((subDivision: SubDivision) => {
              return subDivision.Titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
            }).map((subDivision: SubDivision) => { return { 'IdTipoProducto': productoView.IdTipoProducto, 'Titulo': subDivision.Titulo + ' - ' + this.listaTipoProductos.find((tipoProducto: TipoProducto) => tipoProducto.IdProducto === productoView.IdTipoProducto).Nombre } }) : []);

            this.listaSubCategorias = this.listaSubCategorias.concat(listaSubDivision);

            const listaProds: Array<ISearchResult> = (division.Productos ? division.Productos.filter((producto: Producto) => {
              return producto.Titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().match(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) || producto.PalabrasBusqueda.filter((palabra: string) => { return palabra.toLowerCase().match(term.toLowerCase()) }).length > 0;
            }).map((producto: Producto) => { return { 'IdTipoProducto': productoView.IdTipoProducto, 'Titulo': producto.Titulo + ' - ' + this.listaTipoProductos.find((tipoProducto: TipoProducto) => tipoProducto.IdProducto === productoView.IdTipoProducto).Nombre } }) : []);

            this.listaProds = this.listaProds.concat(listaProds);



            if (division.SubDivision) {
              division.SubDivision.forEach((subDivision: SubDivision) => {
                const listaProds: Array<ISearchResult> = (subDivision.Productos ? subDivision.Productos.filter((producto: Producto) => {
                  return producto.Titulo.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().match(term.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) || producto.PalabrasBusqueda.filter((palabra: string) => { return palabra.toLowerCase().match(term.toLowerCase()) }).length > 0;
                }).map((producto: Producto) => { return { 'IdTipoProducto': productoView.IdTipoProducto, 'Titulo': producto.Titulo } }) : []);

                this.listaProds = this.listaProds.concat(listaProds);
              })

            }
          });
        }
      });
    }
  }

  public goTo(searchOption: ISearchResult): void {
    this.router.navigate(['/lista-productos/' + searchOption.IdTipoProducto], { queryParams: { title: searchOption.Titulo } });
  }

  private inicializar(): void {
    const getTipoProductos$ = this.tipoProductoService.getListaTiposProductos();
    const getProductos$ = this.productosService.getListaTiposProductos();

    zip(getTipoProductos$, getProductos$, (getTipoProductos: ProductView, getProductos: Array<ListaProductosView>) =>
      ({ getTipoProductos, getProductos })).subscribe((allResult: IAllResult) => {
        this.listaProductos = allResult.getProductos;
        this.listaTipoProductos = allResult.getTipoProductos.TipoProductos;
      }, (error: Error) => {

      });
  }
}

interface IAllResult {
  getTipoProductos: ProductView;
  getProductos: Array<ListaProductosView>;
}

interface ISearchResult {
  IdTipoProducto: number;
  Titulo: string;
}
