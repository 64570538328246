import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { FooterInformation } from 'src/app/classes/FooterInformation';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor(private http: HttpClient) { }

  public getContactInformation() {
    const endpoint: string = "../../../assets/Endpoints/Response/getFooterInformation.json";
		return this.http.get<FooterInformation>(endpoint)
			.pipe(
				tap(() => { })
			);
	}
}
