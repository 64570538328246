import { Component, OnInit } from '@angular/core';
import { faBell, faQuestion, faUserEdit, faGlobe, faSignOutAlt, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  faBell = faBell;
  faUserEdit = faUserEdit;
  faQuestion = faQuestion;
  faGlobe = faGlobe;
  faSignOutAlt = faSignOutAlt;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;

  constructor(
  ) {

  }

  ngOnInit() {
  }

  public goToInicio():void {
    window.location.href = 'index.html';
  }
}
