import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { HomeInformation } from 'src/app/classes/HomeInformation';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  public getHomeInformation() {
    const endpoint: string = "../../../assets/Endpoints/Response/getHomeInformation.json";
		return this.http.get<HomeInformation>(endpoint)
			.pipe(
				tap(() => { })
			);
	}
}
