import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Producto } from 'src/app/classes/Producto';
import { faCircle, faFilePdf, faFileWord, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Archivo } from 'src/app/classes/Archivo';
import Swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FilePreviewComponent } from '../../shared/file-preview/file-preview.component';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.scss']
})
export class ProductoDetalleComponent implements OnInit {

  @Input() productoDetalle: Producto;
  @Input() Titulo: string;
  @Input() SubTitulo: string;

  @Output() goBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  public faCircle: IconDefinition = faCircle;
  public faFilePdf: IconDefinition = faFilePdf;
  public faFileWord: IconDefinition = faFileWord;

  constructor(private dialog: MatDialog, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    window.scroll(0, 0);
  }

  public volverLista(): void {
    this.goBack.emit(true);
  }

  public getPreview(file: Archivo): void {
    const extension: string = file.Type;
    switch (extension.toLocaleLowerCase()) {
      case 'doc':
      case 'docx':
      case 'pdf':
        this.checkDownload(extension, file);
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        this.getFilePreview(file);
        break;
      default:
        this.checkDownload(extension, file);
        break;
    }
  }

  private checkDownload(extension: string, file: any): void {
    Swal.fire({
      title: '¿Está seguro?',
      text: "¿Está seguro que quiere descargar este archivo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#168f5d",
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, descargar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.getFilePreview(file);
      }
    })
  }

  private getFilePreview(file: Archivo): void {
    switch (file.Type.toLocaleLowerCase()) {
      case 'pdf':
      case 'doc':
      case 'docx':
      case 'zip':
        this.downloadFile(file);
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        this.openDialogPreview(file);
        break;
      default:
        this.downloadFile(file);
        break;
    }
  }

  private openDialogPreview(file: Archivo): void {
    let objectURL = URL.createObjectURL(file);
    const image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    const dialogRef = this.dialog.open(FilePreviewComponent, {
      width: '500px',
      data: { 'file': image, 'extension': file.Type },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.downloadFile(file);
      }
    });
  }

  private downloadFile(file: Archivo): void {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/' + file.Url;
    link.download = file.Name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
