import { Component, OnInit } from '@angular/core';
import { faHandsHelping, faMedal, faPeopleCarry, faNetworkWired, faCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss']
})
export class BeneficiosComponent implements OnInit {
  scrolled: boolean;
  faHandsHelping = faHandsHelping;
  faMedal = faMedal
  faCircle = faCircle;
  faPeopleCarry = faPeopleCarry;
  faNetworkWired = faNetworkWired;

  constructor() { }

  ngOnInit() {
  }

}
