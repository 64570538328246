import { Component, OnInit } from '@angular/core';
import { faMapMarkedAlt, faCircle } from '@fortawesome/free-solid-svg-icons'
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IframeMapComponent } from './iframe-map/iframe-map.component';
import { SucursalesService } from './sucursales.service';
import { SucursalFull } from 'src/app/classes/SucursalFull';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {
  public faMapMarked = faMapMarkedAlt;
  public faCircle = faCircle;
  public officeInformation: Array<SucursalFull> = new Array<SucursalFull>();

  constructor(public dialog: MatDialog, private officeSerive: SucursalesService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getOfficeInformation();
  }

  public openBottomSheet(mapIframe: string, nombreSucursal: string): void {

    this.dialog.open(IframeMapComponent, {
      width: '560px',
      data: { map: this.setOfficeMap(mapIframe) }
    });
  }

  private setOfficeMap(mapUrl): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
  }

  private getOfficeInformation() {
    this.officeSerive.getOfficeInformation().subscribe((officeInfo: Array<SucursalFull>) => {
      this.officeInformation = officeInfo;
    })
  }
}
