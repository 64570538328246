import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SucursalesService {
  constructor(private http: HttpClient) { }

  public getOfficeInformation() {
    const endpoint: string = "../../../assets/Endpoints/Response/getOfficesInformation.json";
		return this.http.get<any>(endpoint)
			.pipe(
				tap(() => { })
			);
	}
}
