import { Beneficio } from './Beneficio';
import { Caracteristicas } from './Caracteristica';
import { Archivo } from './Archivo';

export class Producto {
  public Titulo: string;
  public SubTitle: string;
  public InfoSistema: Array<string>;
  public InfoSistemaLista: Array<string>;
  public IndicacionesClinicas: Array<string>;
  public Beneficios: Array<Beneficio>;
  public Caracteristicas: Array<Caracteristicas>;
  public ListaFolleto: Array<Archivo>;
  public ListaFoto: Array<any>;
  public FotoCategoria: string;
  public PalabrasBusqueda: Array<string>;

  public constructor(){

  }
}
