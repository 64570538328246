import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Interes } from 'src/app/classes/Interes';
import { Sucursal } from 'src/app/classes/Sucursal';
import { ContactService } from './contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactInformation } from 'src/app/classes/ContactInformation';
import { ConsultaEmail } from 'src/app/classes/ConsultaEmail';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  public interesList: Array<Interes> = new Array<Interes>();
  public sucursalList: Array<Sucursal> = new Array<Sucursal>();
  public canShow: boolean = false;

  constructor(private spinner: NgxSpinnerService, private fb: FormBuilder, private contactService: ContactService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getContactInformation();
    this.initialize();
  }

  public checkForm(): boolean {
    return (!this.contactForm.valid || this.contactForm.controls['email'].value !== this.contactForm.controls['emailCheck'].value);
  }

  public EnviarEmail(): void {
    const formValue = this.contactForm.value;
    let consultaEmail: ConsultaEmail = new ConsultaEmail(formValue.nombreApellido, formValue.interes, formValue.email, formValue.consulta);
    this.contactService.sendEmail(consultaEmail).subscribe((result) => {
      Swal.fire({
        icon: 'success',
        title: 'Su consulta ha sido enviada exitosamente!',
        showConfirmButton: true
      });

      this.initialize();
    }, (error: Error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se pudo enviar su consulta!',
        footer: 'Intente en unos momentos o llamenos por telefono.'
      })
    });

    // let htmlContent = "Interes de la consulta: " + consultaEmail.Interes + "\n\nCuerpo de la consulta: " + consultaEmail.Consulta + "\n\n";

    // let mailText = "mailto:info@brainmedical.com.ar+?subject=Brain Medical - Consulta desde el portal&body=" + encodeURIComponent(htmlContent);
    // window.location.href = mailText;
  }

  private initialize(): void {
    this.contactForm = this.fb.group({
      nombreApellido: [null, Validators.compose([Validators.required])],
      interes: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      emailCheck: [null, Validators.compose([Validators.required, Validators.email,])],
      consulta: [null, Validators.compose([Validators.required])],
      recaptchaReactive: [null, Validators.compose([Validators.required])]
    });
  }

  private getContactInformation() {
    this.contactService.getContactInformation().subscribe((contactInfo: ContactInformation) => {
      this.interesList = contactInfo.Intereses;
      this.sucursalList = contactInfo.Sucursales;
      this.canShow = true;
      setTimeout(() => {
        this.spinner.hide();
      }, 1000);
    })
  }
}
