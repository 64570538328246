import { Component, OnInit } from '@angular/core';
import { faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FooterService } from './footer.service';
import { FooterInformation } from 'src/app/classes/FooterInformation';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public footerInformation: FooterInformation
  faInstagramSquare: IconDefinition = faInstagramSquare;
  faTwitterSquare: IconDefinition = faTwitterSquare;
  faCircle = faCircle;
  public canShow: boolean = false;

  constructor(private footerService: FooterService) { }

  ngOnInit() {
    this.footerService.getContactInformation().subscribe((footerInformation: FooterInformation) => {
      this.footerInformation = footerInformation;
      this.canShow = true;
    })
  }

  public getIcon(iconName: string): IconDefinition {
    switch (iconName) {
      case 'faInstagramSquare':
        return this.faInstagramSquare;
      case 'faTwitterSquare':
        return this.faTwitterSquare;
      default:
        break;
    }
  }

}
