import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css']
})
export class FilePreviewComponent implements OnInit {
  public imageURL: string;
  public uploadForm: FormGroup;
  public canShow: boolean = false;
  constructor(private dialogRef: MatDialogRef<FilePreviewComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.canShow = true;
      this.spinner.hide();
    }, 1000);
  }

  public onNoClick(descargar: boolean): void {
		this.dialogRef.close(descargar);
	}
}
