export class ConsultaEmail {
  public NombreApellido: string;
  public Interes: string;
  public Email: string;
  public Consulta: string;

  public constructor(nomApe: string, interes: string, email: string, consulta: string) {
    this.NombreApellido = nomApe;
    this.Interes = interes;
    this.Email = email;
    this.Consulta = consulta;
  }
}
