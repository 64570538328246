import { Component, OnInit, Input } from '@angular/core';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})

export class NavbarComponent implements OnInit {
  @Input() mode: string;

  faLongArrowAltLeft = faLongArrowAltLeft;

  constructor(
  ) {}

  ngOnInit(){
    window.addEventListener('scroll', this.scroll, true);
  }

  public scroll(): boolean {
    let pos = window.pageYOffset;
    if (pos > 100) {
      return true;
    }
    return false;
  }

  public closeMenu(): void{
    if(window.screen.width <= 500){
      $('#buttonMenu').click();
    }
  }
}
